.collection-tabs__list-link {
  white-space: nowrap;
  border-radius: var(--button-border-radius, 6px);
  background: rgba(var(--color-body-rgb), .05);
  height: 31px;
  color: rgba(var(--color-body-rgb), .8);
  align-items: center;
  margin: 0 2px 2px 0;
  padding: 5px 15px;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1;
  transition: all .25s cubic-bezier(.104, .204, .492, 1);
  display: inline-flex;
}

.collection-tabs__list-link:hover, .active.collection-tabs__list-link {
  color: #fff;
  background: var(--color-accent);
}

.collection-tabs {
  overflow: hidden;
}

.collection-tabs__list {
  text-align: left;
  white-space: nowrap;
  grid-gap: 10px;
  scrollbar-width: none;
  margin-top: 15px;
  display: block;
  overflow-x: scroll;
}

.collection-tabs__list::-webkit-scrollbar {
  display: none;
}

.collection-tabs__list-link + .collection-tabs__list-link {
  margin-left: 10px;
}

@media only screen and (width <= 767px) {
  .collection-tabs__list {
    width: 100%;
  }
}

.section-collection-tabs .products.carousel .columns {
  display: none;
}

.section-collection-tabs .products.carousel .columns.carousel__slide {
  display: block;
}

@media only screen and (width <= 767px) {
  .section-collection-tabs .products.carousel .carousel__slide {
    width: calc(50vw - 15px);
  }
}

select:disabled {
  opacity: .13;
  cursor: not-allowed;
}

.fs-advanced-search select {
  max-width: 25% !important;
}

@media only screen and (width <= 767px) {
  .fs-advanced-search {
    flex-direction: column !important;
  }

  .fs-advanced-search select {
    max-width: 100% !important;
  }
}
/*# sourceMappingURL=index.css.map */
